import {
  AutoMode as AutoModeIcon,
  AutoFixOff as AutoFixOffIcon,
} from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { find } from 'lodash';
import React, { Fragment, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery, useQueryClient } from 'react-query';

import { tradersApi } from 'api';
import { requisitesGroupApi } from 'api/requisites-groups.api';
import {
  CloseDialogResult,
  CopyTextId,
  CrudTable,
  CrudTableActionType,
  DataGridColumnDefinition,
  QueryFilters,
  dataGridColumns,
} from 'components';
import { ROUTE_PATH } from 'constants/routes';
import { FilterDefinitionType, QueryKey } from 'enums';
import { useMutation, usePartialQuery, useUser } from 'hooks';
import { TranslationNamespace } from 'i18n';
import { FilterDefinition, RequisitesGroup } from 'types';
import { requisitesUtils } from 'utils';

import { OrderGroupAutomationDialog } from './OrderGroupAutomationDialog';

type RequisitesGroupsFilters = Partial<{
  traderId: string;
}>;

export const RequisitesGroupList: React.FC = () => {
  const { t } = useTranslation(TranslationNamespace.Common, {
    keyPrefix: 'features.requisites.group_list',
  });
  const { role, isTrader, isAdmin, isTechOperator, isOperator } = useUser();
  const canManage = useMemo(
    () => isAdmin || isTechOperator,
    [isAdmin, isTechOperator],
  );
  const queryClient = useQueryClient();

  const queryResult = usePartialQuery(
    QueryKey.RequisitesGroupsPaginated,
    requisitesGroupApi.getAllPaginatedAsRole(role),
  );

  const queryResultTraders = useQuery(
    QueryKey.Traders,
    () => tradersApi.getAllAsRole(role)(),
    {
      enabled: canManage || isOperator,
    },
  );

  const { mutate: remove } = useMutation(
    requisitesGroupApi.removeAsRole(role),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryKey.RequisitesGroupsPaginated);
      },
    },
  );

  const { mutate: updateOrderAutomation } = useMutation(
    requisitesGroupApi.updateOrderAutomationAsRole(role),
  );

  const [orderAutomationDialogProps, setOrderAutomationDialogProps] = useState<{
    open: boolean;
    data?: RequisitesGroup;
  }>({ open: false });

  const columns = useMemo(
    (): DataGridColumnDefinition<RequisitesGroup>[] => [
      dataGridColumns.getIdColumn(),
      {
        header: t('fields.trader'),
        valueGetter: (item) => item.user?.name,
        hidden: isTrader,
      },
      {
        header: t('fields.name'),
        valueKey: 'name',
      },
      {
        header: t('fields.automation_api_key'),
        valueGetter: (item) => <CopyTextId id={item.automationApiKey} />,
      },
      {
        header: t('fields.order_automation_type'),
        valueGetter: (item) => (
          <Fragment>
            {requisitesUtils.getOrderAutomationTypeLabel(
              item.orderAutomationType,
            )}
            {item.autoDisconnect && (
              <Tooltip title={t('fields.auto_disconnect')}>
                <AutoFixOffIcon className="tw-ml-2" />
              </Tooltip>
            )}
          </Fragment>
        ),
      },
    ],
    [t, isTrader],
  );

  const detailsPath = useMemo(() => {
    if (isTrader) {
      return ROUTE_PATH.TRADER.PAYIN_REQUISITES_GROUP_DETAILS;
    } else if (isAdmin) {
      return ROUTE_PATH.ADMIN.PAYIN_REQUISITES_GROUP_DETAILS;
    } else if (isTechOperator) {
      return ROUTE_PATH.TECH_OPERATOR.PAYIN_REQUISITES_GROUP_DETAILS;
    }
  }, [isTrader, isTechOperator, isAdmin]);

  const handleOrderAutomation = useCallback((item: RequisitesGroup) => {
    setOrderAutomationDialogProps({ open: true, data: item });
  }, []);

  const handleOrderAutomationDialogClose = useCallback(
    (
      result: CloseDialogResult<Pick<RequisitesGroup, 'orderAutomationType'>>,
    ) => {
      if (
        canManage &&
        result.ok &&
        result.data &&
        orderAutomationDialogProps.data
      ) {
        updateOrderAutomation(
          {
            id: orderAutomationDialogProps.data.id,
            data: result.data,
          },
          {
            onSuccess: () => {
              setOrderAutomationDialogProps({ open: false });
              queryClient.invalidateQueries(QueryKey.RequisitesGroupsPaginated);
            },
          },
        );
      } else {
        setOrderAutomationDialogProps({ open: false });
      }
    },
    [
      canManage,
      orderAutomationDialogProps.data,
      queryClient,
      updateOrderAutomation,
    ],
  );

  const filtersDefinitions: FilterDefinition<RequisitesGroupsFilters>[] =
    useMemo(
      () => [
        {
          label: t('filters.trader'),
          name: 'traderId',
          type: FilterDefinitionType.Trader,
          traders: queryResultTraders.data,
          getDisplayName: (traderId: string) =>
            find(queryResultTraders.data, { id: traderId })?.user?.name,
          hidden: isTrader,
        },
      ],
      [isTrader, queryResultTraders.data, t],
    );

  return (
    <Fragment>
      <QueryFilters filtersDefinitions={filtersDefinitions} />
      <CrudTable
        queryResult={queryResult}
        columns={columns}
        paginated
        hideActions={!isTrader && !canManage}
        actions={[
          {
            type: CrudTableActionType.Custom,
            hidden: !canManage,
            render: (item) => (
              <IconButton
                color="primary"
                onClick={() => {
                  handleOrderAutomation(item);
                }}
              >
                <AutoModeIcon />
              </IconButton>
            ),
          },
          {
            type: CrudTableActionType.Details,
            path: detailsPath,
          },
          {
            type: CrudTableActionType.Remove,
            onRemove: (item, { close }) =>
              remove(item.id, {
                onSuccess: close,
              }),
          },
        ]}
      />
      <OrderGroupAutomationDialog
        {...orderAutomationDialogProps}
        onClose={handleOrderAutomationDialogClose}
      />
    </Fragment>
  );
};
